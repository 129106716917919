import axiosIns from '@/libs/axios'

export default {
  getAllFintech: (() => axiosIns.get('accounting/ins-campus-merchant-service/fintech')),
  getAllFintechMerchantName: (id => axiosIns.get(`accounting/ins-campus-merchant-service/fintech-merchant-name/${id}`)),
  getAll: (() => axiosIns.get('accounting/ins-campus-merchant-service')),
  create: (payload => axiosIns.post('accounting/ins-campus-merchant-service', payload)),
  getOne: (id => axiosIns.get(`accounting/ins-campus-merchant-service/${id}`)),
  getFintechMerchantOtc: (id => axiosIns.get(`accounting/ins-campus-merchant-service/fintech-otc/${id}`)),
  update: ((payload, id) => axiosIns.put(`accounting/ins-campus-merchant-service/${id}`, payload)),
  delete: (id => axiosIns.delete(`accounting/ins-campus-merchant-service/${id}`)),
  getAllInstitution: (() => axiosIns.get('accounting/ins-campus-merchant-service/institute')),
  getAllFintechService: (id => axiosIns.get(`accounting/fintech-service/service/${id}`)),
  getAllCampus: (() => axiosIns.get('accounting/ins-campus-merchant-service/campus')),

}
