<template>
  <b-card>
    <b-modal
      id="institution-campus-merchant-service-modal"
      size="lg"
      :title="`${
        updateId ? 'Update' : 'Add'
      } Institute Campus Merchant Service`"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      :ok-disabled="isSubmitting"
      no-close-on-backdrop
      @ok.prevent="handleInstituteCampusMerchantServiceFormSubmit"
      @cancel="resetInstituteCampusMerchantService"
      @close="resetInstituteCampusMerchantService"
    >
      <b-form @submit.prevent>
        <b-row>
          <!-- Fintech -->
          <b-col cols="12">
            <b-form-group
              label="Fintech"
              label-cols-md="4"
            >
              <v-select
                v-model="insFormData.fintechId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Fintech"
                :options="fintechOptions"
                :reduce="(val) => val.id"
                :disabled="fintechOptions.length ? false : true"
                @input="onChangeFintech"
              />

              <span
                v-if="allerros.fintech || false"
                :class="[allerros.fintech ? 'text-danger' : ' d-none']"
              >{{ allerros.fintech[0] }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Fintech Merchant"
              label-cols-md="4"
            >
              <v-select
                v-model="insFormData.merchantId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="fintech_merchant_name"
                placeholder="Select Fintech Merchant"
                :options="fintechMerchantNameOptions"
                :reduce="(val) => val.id"
                :disabled="fintechMerchantNameOptions.length ? false : true"
                @input="onChangeFintechMerchant"
              />
              <span
                v-if="allerros.fintechMerchant"
                :class="[allerros.fintechMerchant ? 'text-danger' : ' d-none']"
              >{{ allerros.fintechMerchant[0] }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Campus"
              label-cols-md="4"
            >
              <v-select
                v-model="insFormData.campusIds"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="short_name"
                placeholder="Select Campus"
                :options="campusOptions"
                :reduce="(val) => val"
                multiple
                :disabled="campusOptions.length ? false : true"
                @input="onChangeCampuses"
              />
              <span
                v-if="allerros.campuses"
                :class="[allerros.campuses ? 'text-danger' : ' d-none']"
              >{{ allerros.campuses[0] }}</span>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isOTCService && isFranchise"
            cols="12"
          >
            <b-form-group
              label="Revenue Disbursement Account"
              label-cols-md="4"
            >
              <v-select
                v-model="insFormData.disbursementFintechMerchantId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="fintech_merchant_name"
                placeholder="Select Revenue Disbursement Account"
                :options="fintechMerchantNameOptions"
                :reduce="(val) => val.id"
                :disabled="fintechMerchantNameOptions.length ? false : true"
                @input="onChangeDisbursementFintechMerchant"
              />
              <span
                v-if="allerros.disbursementFintechMerchantId"
                :class="[
                  allerros.disbursementFintechMerchantId
                    ? 'text-danger'
                    : ' d-none',
                ]"
              >{{ allerros.disbursementFintechMerchantId[0] }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-cols-md="4"
            >
              <b-form-radio-group
                v-model="insFormData.status"
                :options="fintechStatusOptions"
                name="radio-inline"
                :class="[allerros.status ? 'is-invalid' : '']"
              />

              <span
                v-if="allerros.status"
                :class="[allerros.status ? 'text-danger' : ' d-none']"
              >{{ allerros.status[0] }}</span>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-row class="mt-2">
                <b-col class="d-flex">
                  <b-form-group
                    label="Fintech Service"
                    class="col-3"
                  >
                    <v-select
                      v-model="childFormData.fintechService"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      placeholder="Select Service"
                      :options="fintechServiceOptions"
                      :disabled="fintechServiceOptions.length ? false : true"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Convenience Fee"
                    class="col-3"
                  >
                    <b-form-input
                      v-model="childFormData.convenientFee"
                      placeholder="Enter Convenience Fee"
                      :disabled="fintechServiceOptions.length ? false : true"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-group
                    label-sr-only
                    label="Submit"
                    class="col-2 align-self-end"
                  >
                    <b-button
                      type="submit"
                      variant="outline-primary"
                      class="mr-1"
                      :disabled="
                        !childFormData.convenientFee ||
                          !childFormData.fintechService
                          ? true
                          : false
                      "
                      @click.prevent="handleChildFormSubmit"
                    >
                      {{ isUpdateService ? "Update" : "Add" }}
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
            <span
              v-if="allerros.services"
              :class="[allerros.services ? 'text-danger' : ' d-none']"
            >{{ allerros.services[0] }}</span>
          </b-col>
        </b-row>
      </b-form>

      <!-- Child Grid -->
      <vue-good-table
        :columns="modalColumns"
        :rows="finalServiceData"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :line-numbers="true"
        theme="my-theme"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span class="d-flex">
              <b-button
                class="mr-1 px-1"
                variant="outline-warning"
                @click="editServiceConfig(props.row)"
              >Edit</b-button>
              <b-button
                class="px-1"
                variant="outline-danger"
                @click.prevent="deleteServiceConfig(props.row)"
              >Remove</b-button>
            </span>
          </span>

          <span v-else-if="props.column.field === 'convenientType'">
            {{
              props.formattedRow[props.column.field] == 1
                ? "Percentage"
                : "Fixed"
            }}
          </span>

          <span v-else-if="props.column.field === 'status'">
            {{
              props.formattedRow[props.column.field] == 1
                ? "Active"
                : "Inactive"
            }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '20', '30', '40', '50']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Institute Campus Merchant Service
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          class="mr-2"
          @click="onCreate"
        >
          Add Institute Campus Merchant Service
        </b-button>
        <!-- <b-button
          variant="outline-info"
          style="text-transform: capitalize"
          @click="onRefresh"
        >
          <RefreshCwIcon />
        </b-button> -->
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      v-if="!isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <!--            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>-->
          </span>
        </span>

        <span v-else-if="props.column.field === 'status'">
          {{
            props.formattedRow[props.column.field] == 1 ? "Active" : "Inactive"
          }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!--  pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <Pagination
          :v-if="props"
          :pagination-props="props"
          :refresh-grid="refreshGrid"
        />
      </template>
    </vue-good-table>
    <preloader v-if="isLoading" />
    <!-- Audit Activity Modal -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton,
  BFormRadioGroup, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import InstituteCampusMerchantService from '@/@service/api/accounting/fintech/InstituteCampusMerchant.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import Pagination from '@/views/components/vue-good-table-pagination/Pagination.vue'
import Preloader from '../components/preloader/preloader.vue'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    VueGoodTable,
    BFormInput,
    BFormSelect,
    BPagination,
    BButton,
    BFormRadioGroup,
    vSelect,
    Preloader,
    Pagination,
    BLink,
    ActivityModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      columns: [
        {
          label: 'Fintech Name',
          field: 'fintech_name',
        },
        {
          label: 'Collection Account Name',
          field: 'account_name',
        },
        {
          label: 'Branch Name',
          field: 'campus_names.label',
        },

        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      modalColumns: [
        {
          label: 'Service',
          field: 'fintechServiceId.name',
        },
        {
          label: 'Convenience Fee',
          field: 'convenientFee',
        },
        {
          label: 'Convenience Type',
          field: 'convenientType',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      institutionId: 1,
      insFormData: {
        id: null,
        fintechId: null,
        merchantId: null,
        campusIds: null,
        disbursementFintechMerchantId: null,
        status: null,
      },
      childFormData: {
        fintechService: {},
        convenientFee: null,
      },
      finalServiceData: [],
      institutionOptions: [],
      fintechServiceOptions: [],
      fintechMerchantNameOptions: [],
      campusOptions: [],
      fintechOptions: [],
      isLoading: false,
      isUpdateService: false,
      fintechStatusOptions: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],
      allerros: {
        fintech: null,
        fintech_merchant: null,
        campus_ids: null,
        services: null,
        status: null,
      },
      isSubmitting: false,
      isOTCService: false,
      isFranchise: false,

      // Activity Log Data
      tag: 'institute-campus-merchant-service',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    institutionId(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getInstituteCampusMerchantServiceGridData()
      }
    },
  },
  created() {
    this.getInstituteCampusMerchantServiceGridData()
  },
  methods: {
    onChangeFintech(val) {
      const fintechId = val
      if (!fintechId) return
      this.childFormData = {
        fintechService: {},
        convenientFee: null,
      }
      this.insFormData.merchantId = ''
      this.finalServiceData = []
      this.getAllFintechService(fintechId)
      this.getFintechMerchantNameService()
    },
    getFintech() {
      InstituteCampusMerchantService.getAllFintech().then(res => {
        this.fintechOptions = res.data.data
      })
    },
    getFintechMerchantNameService() {
      if (!this.insFormData.fintechId) return
      InstituteCampusMerchantService.getAllFintechMerchantName(this.insFormData.fintechId).then(
        res => {
          this.fintechMerchantNameOptions = res.data.data
        },
      )
    },
    getCampus() {
      InstituteCampusMerchantService.getAllCampus().then(res => {
        this.campusOptions = res.data.data
      })
    },
    getAllFintechService(fintechId) {
      InstituteCampusMerchantService.getAllFintechService(fintechId).then(
        res => {
          const data = []
          if (res.data.data.length) {
            res.data.data.forEach(item => {
              data.push(item.payment_service)
            })
          }
          this.fintechServiceOptions = data
        },
      )
    },
    handleInstituteCampusMerchantServiceFormSubmit() {
      if (this.updateId) {
        this.updateInstituteCampusMerchantService()
      } else {
        this.saveInstituteCampusMerchantService()
      }
    },
    handleChildFormSubmit() {
      if (
        !this.childFormData.convenientFee
        || !this.childFormData.fintechService
      ) {
        FemsToastrService.error(
          'Fintech Service and Convenience fee is required',
        )
        return
      }
      const obj = {
        convenientFee: this.childFormData.convenientFee,
        convenientType: this.childFormData.convenientType || null,
        fintechServiceId: this.childFormData.fintechService,
      }
      if (this.checkAlreadyExist(obj) && !this.isUpdateService) {
        FemsToastrService.warning('This Service Already Exist')
        this.childFormData = {}
        return
      }
      if (this.isUpdateService) {
        const newData = []
        const indexService = this.childFormData.index
        this.finalServiceData.forEach((item, i) => {
          if (i === indexService) {
            newData.push(obj)
          } else {
            newData.push(item)
          }
        })
        this.finalServiceData = newData
        this.isUpdateService = false
      } else {
        this.finalServiceData.push(obj)
      }
      this.childFormData = {}
    },

    checkAlreadyExist(obj) {
      let isExist = false
      this.finalServiceData.forEach(item => {
        if (
          item.fintechServiceId.id === obj.fintechServiceId.id
          && !this.isUpdateService
        ) {
          isExist = true
        } else if (
          item.fintechServiceId.id === obj.fintechServiceId.id
          && this.isUpdateService
          && item.convenientFee === obj.convenientFee
        ) {
          isExist = true
        }
      })
      return isExist
    },
    formValidation() {
      if (!this.insFormData?.fintechId) {
        this.allerros.fintech = ['This fintech is required!']
        return false
      }
      this.allerros.fintech = null

      if (!this.insFormData?.merchantId) {
        this.allerros.fintechMerchant = ['This fintech merchant is required!']
        return false
      }
      this.allerros.fintechMerchant = null

      if (!this.insFormData?.campusIds > 0) {
        this.allerros.campuses = ['The Campus is required!']
        return false
      }
      this.allerros.campuses = null
      if (this.insFormData?.status === null || typeof this.insFormData?.status === 'undefined') {
        this.allerros.status = ['The status is required!']
        return false
      }
      this.allerros.status = null
      if (!this.finalServiceData.length > 0) {
        this.allerros.services = ['This service is required!']
        return false
      }
      this.allerros.services = null
      this.allerros = {}
      return true
    },
    saveInstituteCampusMerchantService() {
      this.isSubmitting = true
      const serviceData = []
      if (this.finalServiceData.length) {
        this.finalServiceData.forEach(item => serviceData.push({
          convenientFee: item.convenientFee,
          convenientType: item.convenientType || null,
          fintechServiceId: item.fintechServiceId.id,
        }))
      }

      const campusesData = []
      if (this.insFormData.campusIds && this.insFormData.campusIds.length) {
        this.insFormData.campusIds.forEach(item => (item.id ? campusesData.push(item.id) : campusesData.push(item)))
      }
      const params = {
        fintech: this.insFormData?.fintechId || null,
        fintechMerchant: this.insFormData?.merchantId || null,
        campuses: campusesData.length ? JSON.stringify(campusesData) : null,
        status: this.insFormData?.status ?? null,
        services: serviceData.length ? JSON.stringify(serviceData) : null,
        disbursementFintechMerchantId:
          this.insFormData?.disbursementFintechMerchantId ?? null,
      }

      const validate = this.formValidation()
      if (!validate) {
        this.isSubmitting = false
        return
      }

      InstituteCampusMerchantService.create(params).then(
        response => {
          const res = response.data
          this.isSubmitting = false

          if (res.status) {
            this.resetInstituteCampusMerchantService()
            this.getInstituteCampusMerchantServiceGridData()
            FemsToastrService.success(res.message)
            this.$bvModal.hide('institution-campus-merchant-service-modal')
            return
          }
          if (res.validationMessages) {
            this.allerros = res.validationMessages
            return
          }
          FemsToastrService.error(res.message)
        },

      ).catch(err => {
        FemsToastrService.error(err.message)
      })
      this.isSubmitting = false
    },
    updateInstituteCampusMerchantService() {
      this.isSubmitting = true
      const validate = this.formValidation()
      if (!validate) {
        this.isSubmitting = false
        return
      }

      const serviceData = []
      if (this.finalServiceData.length) {
        this.finalServiceData.forEach(item => serviceData.push({
          convenientFee: item.convenientFee,
          convenientType: item.convenientType || null,
          fintechServiceId: item.fintechServiceId.id,
        }))
      }
      const campusesData = []
      if (this.insFormData.campusIds && this.insFormData.campusIds.length) {
        this.insFormData.campusIds.forEach(item => (item.id ? campusesData.push(item.id) : campusesData.push(item)))
      }
      const params = {
        fintech: this.insFormData?.fintechId || null,
        fintechMerchant: this.insFormData?.merchantId || null,
        campuses: campusesData.length ? JSON.stringify(campusesData) : null,
        status: this.insFormData?.status ?? null,
        services: serviceData.length ? JSON.stringify(serviceData) : null,
        disbursementFintechMerchantId:
          this.insFormData?.disbursementFintechMerchantId ?? null,
      }
      InstituteCampusMerchantService.update(params, this.updateId).then(
        response => {
          const res = response.data
          this.isSubmitting = false

          if (res.status) {
            this.resetInstituteCampusMerchantService()
            this.getInstituteCampusMerchantServiceGridData()
            FemsToastrService.success(res.message)

            this.$bvModal.hide('institution-campus-merchant-service-modal')
            this.updateId = null
            return
          }
          if (res.validationMessages) {
            this.allerros = res.validationMessages
            return
          }
          FemsToastrService.error(res.message)
        },
      ).catch(err => {
        FemsToastrService.error(err.message)
        this.isSubmitting = false
      })
    },
    getInstituteCampusMerchantServiceGridData() {
      this.isLoading = true
      this.rows = []
      InstituteCampusMerchantService.getAll().then(
        response => {
          const res = response.data
          if (res.status) {
            this.rows = res.data
          }
          this.isLoading = false
        },
      ).catch(() => {
        this.rows = []
        this.isLoading = false
      })
    },
    refreshGrid() {
      this.getInstituteCampusMerchantServiceGridData()
    },
    resetInstituteCampusMerchantService() {
      this.isUpdateService = false
      this.insFormData = {}
      this.fintechOptions = []
      this.fintechMerchantNameOptions = []
      this.campusOptions = []
      this.servicesData = []
      this.allerros = {}
      this.finalServiceData = []
      this.childFormData = {}
      this.childFormData = {
        fintechService: {},
        convenientFee: null,
      }
      this.fintechServiceOptions = []

      this.isOTCService = false
      this.isFranchise = false
    },
    onCreate() {
      this.updateId = null
      this.isSubmitting = false
      this.resetInstituteCampusMerchantService()
      this.openModal()
    },
    async openModal() {
      await this.getFintech()
      await this.getFintechMerchantNameService()
      await this.getCampus()
      await this.$bvModal.show('institution-campus-merchant-service-modal')
    },
    async editRow(rowData) {
      await this.resetInstituteCampusMerchantService()
      this.updateId = rowData.id
      await InstituteCampusMerchantService.getOne(rowData.id).then(response => {
        const res = response.data
        this.insFormData = {
          id: rowData.id,
          fintechId: res.data?.fintech.id,
          merchantId: res.data?.fintech_merchant_id,
          campusIds: res?.data?.campuses,
          status: res.data.status,
          disbursementFintechMerchantId:
            res.data.disbursement_fintech_merchant_id,
        }
        this.finalServiceData = res.data?.servicesData
        this.isOTCService = !!res.data?.isOtcService
        this.isFranchise = !!res.data?.isFranchise
        this.getAllFintechService(this.insFormData.fintechId)
      })
      this.openModal()
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          InstituteCampusMerchantService.delete(rowData.id).then(response => {
            const res = response.response
            if (res.status) {
              this.resetInstituteCampusMerchantService()
              this.getInstituteCampusMerchantServiceGridData()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              FemsToastrService.error(res.message)
            }
          })
        }
      })
    },
    editServiceConfig(rowData) {
      this.childFormData = {
        index: rowData.originalIndex,
        convenientFee: rowData.convenientFee,
        fintechService: rowData.fintechServiceId,
      }
      this.isUpdateService = true
    },
    deleteServiceConfig(rowData) {
      const oldData = this.finalServiceData
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          oldData.splice(rowData.originalIndex, 1)
        }
      })
    },
    onRefresh() {
      this.getInstituteCampusMerchantServiceGridData()
    },

    onChangeFintechMerchant(val) {
      const fintechMerchantId = val
      if (!fintechMerchantId) {
        this.isOTCService = false
        this.insFormData.disbursementFintechMerchantId = ''
        return
      }
      InstituteCampusMerchantService.getFintechMerchantOtc(
        fintechMerchantId,
      ).then(response => {
        const res = response.data
        if (res.status) {
          const paymentServiceCategoryId = res.data.payment_service_category_id
          if (paymentServiceCategoryId === 5) {
            this.isOTCService = true
          } else {
            this.isOTCService = false
            this.insFormData.disbursementFintechMerchantId = ''
          }
        }
      })
      this.insFormData.merchantId = val
    },
    onChangeCampuses(val) {
      if (val.length) {
        const campusIds = []
        const checkType = this.insFormData.campusIds[0]?.type || val[0].type
        val.forEach(item => {
          if (item.type === checkType) {
            campusIds.push(item)
          } else {
            const msg = checkType === 1 ? 'Franchise' : 'Regular'
            FemsToastrService.error(
              `Selected Campus is not ${msg}. please select all campus are ${msg} `,
            )
          }
        })
        if (campusIds[0]?.type === 1) {
          this.isFranchise = true
        } else {
          this.isFranchise = false
        }
        this.insFormData.campusIds = campusIds
      } else {
        this.isFranchise = false
        this.insFormData.disbursementFintechMerchantId = ''
      }
    },
    onChangeDisbursementFintechMerchant(val) {
      if (val === this.insFormData.merchantId) {
        this.insFormData.disbursementFintechMerchantId = {}
        FemsToastrService.error(
          'You selected Fintech Merchant and Revenue Disbursement Account are same, please select different account',
        )
      } else {
        this.insFormData.disbursementFintechMerchantId = val
      }
    },

    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
